import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import { types } from "@vilocnv/allsetra-core";
import { store } from "app/store";
import { AzureMsal } from "./index";
import { Subject } from "rxjs";

export const signalRConnectionEvent = new Subject();

class SignalRService {
  static hubConnection: HubConnection | null = null;

  static createHubConnection = async (): Promise<HubConnection | null> => {
    if (this.hubConnection?.state === "Connected") {
      return this.hubConnection;
    }

    const accessTokenResponse = await AzureMsal.acquireToken();

    if (!accessTokenResponse) {
      console.error("Access token is required for connection with SignalR.");
      return this.hubConnection;
    }

    const userEmail = store.getState().rootReducer.dashboardReducer.userEmail;

    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${
          import.meta.env.VITE_API_BASE_LIVE_UPDATE_URL
        }/?source=adminportal&user=${userEmail}`,
        {
          accessTokenFactory: () => accessTokenResponse.idToken ?? "",
          headers: {
            "X-Subscription": `${import.meta.env.VITE_API_HEADER_SUBSCRIPTION}`,
          },
        }
      )
      .withAutomaticReconnect()
      .build();

    try {
      await newConnection.start();
      console.log("Connected SignalR!");

      this.hubConnection = newConnection;

      signalRConnectionEvent.next("Connected");

      return newConnection;
    } catch (e: any) {
      console.error("Connection failed: ", e);
      signalRConnectionEvent.next("Disconnected");
      throw e;
    }
  };

  static registerOnServerEvent = (
    eventName: types.BackendEventsEnum,
    callback: (event: any) => void
  ) => {
    this.hubConnection?.on("EventRaised", (event) => {
      if (event.eventName === eventName) {
        callback(event);
      }
    });
  };
}

export default SignalRService;
