import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectSignalREventsReducerLoading = (state: RootState) =>
  state.rootReducer.signalREventsReducer.loading;

export const selectSignalREventsReducerLastEvent = (state: RootState) =>
  state.rootReducer.signalREventsReducer.lastEvent;

export const selectSignalREventsState = createSelector(
  selectSignalREventsReducerLoading,
  selectSignalREventsReducerLastEvent,
  (loading, lastEvent) => ({
    loading,
    lastEvent,
  })
);
