import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, cloneDeep } from "lodash";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { MapAlarmCard, types } from "@vilocnv/allsetra-core";
import AlarmIcon from "assets/icons/common/AlarmIcon.png";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";
import { selectSignalREventsState } from "app/data/selectors/signalREventsSelectors";
import { ISignalREvent } from "app/features/signalREvents/signalREventsSlice";

interface MarkerProps {
  objects: types.IAlarm[];
  selectedMarker: number | null;
  handleMarkerClick: (markerIndex: any) => void;
  selectedAlarmId: string | null;
  alarmGeozone: types.IGeozone | null;
}

const AlarmMarkers: FC<MarkerProps> = ({
  objects,
  selectedMarker,
  handleMarkerClick,
  selectedAlarmId,
  alarmGeozone,
}) => {
  const { t } = useTranslation();
  const [updatedObjects, setUpdatedObjects] = useState<types.IAlarm[]>(objects);
  const { lastEvent: event } = useAppSelector(selectSignalREventsState);

  const markersObjects = useMemo(() => {
    if (isEmpty(selectedAlarmId)) {
      return updatedObjects;
    } else {
      const alarm = updatedObjects?.find(
        (obj) => obj.uniqueId === selectedAlarmId
      );
      return alarm ? [alarm] : updatedObjects;
    }
  }, [updatedObjects, selectedAlarmId]);

  const checkSignalREvent = useCallback((event: ISignalREvent | undefined) => {
    if (event) {
      if (
        event?.eventName === types.BackendEventsEnum.ObjectLocationReportedEvent
      ) {
        const { uniqueId, latitude, longitude } = event;

        const updatedObjectsList = cloneDeep(updatedObjects).map((obj) => {
          if (obj.object.uniqueId === uniqueId) {
            return {
              ...obj,
              object: {
                ...obj.object,
                location: {
                  ...obj.object.location,
                  latitude,
                  longitude,
                },
              },
            };
          }
          return obj;
        });

        setUpdatedObjects(updatedObjectsList);
      } else if (
        event?.eventName === types.BackendEventsEnum.ObjectIconUpdatedEvent
      ) {
        const { uniqueId, iconUrl } = event;

        const updatedObjectsList = cloneDeep(updatedObjects).map((obj) => {
          if (obj.object.uniqueId === uniqueId) {
            return {
              ...obj,
              object: {
                ...obj.object,
                objectType: {
                  ...obj.object.objectType,
                  icon: {
                    ...obj.object.objectType.icon,
                    url: iconUrl,
                  },
                },
              },
            };
          }
          return obj;
        });

        setUpdatedObjects(updatedObjectsList);
      }
    }
  }, []);

  useEffect(() => {
    checkSignalREvent(event);
  }, [event, checkSignalREvent]);

  const renderAlarm = (alarmObject: types.IAlarm, index: number) => {
    return (
      <MarkerF
        position={{
          lat: alarmObject.location?.latitude || 0,
          lng: alarmObject.location?.longitude || 0,
        }}
        icon={{
          url: AlarmIcon,
          scaledSize: new window.google.maps.Size(28, 28),
        }}
        onClick={() => handleMarkerClick(index)}
      >
        {(selectedMarker === index ||
          alarmObject.uniqueId === selectedAlarmId) && (
          <InfoWindowF
            onCloseClick={() => handleMarkerClick(null)}
            position={{
              lat: alarmObject.location?.latitude || 0,
              lng: alarmObject.location?.longitude || 0,
            }}
          >
            <MapAlarmCard
              name={alarmObject?.object.name}
              aNumber={alarmObject?.aNumber}
              // @ts-ignore
              alarmType={alarmObject?.alarmType?.name}
              hasImmobilizer={alarmObject?.hasImmobilizer}
              ignitionStatus={alarmObject?.ignitionStatus}
              resolvedAddress={alarmObject?.location?.resolvedAddress || ""}
              created={alarmObject?.created}
              alarmPerson={alarmObject?.object?.alarmOwner?.name || ""}
              geozoneName={alarmGeozone?.name || "N/A"}
              translator={t}
            />
          </InfoWindowF>
        )}
      </MarkerF>
    );
  };

  const renderObject = (alarmObject: types.IAlarm, index: number) => {
    const objectIconUrl = alarmObject?.object.objectType?.icon?.url || "";

    return (
      <MarkerF
        key={objectIconUrl}
        position={{
          lat: alarmObject.object.location?.latitude || 0,
          lng: alarmObject.object.location?.longitude || 0,
        }}
        icon={
          objectIconUrl
            ? {
                url: objectIconUrl,
                scaledSize: new window.google.maps.Size(28, 28),
              }
            : undefined
        }
        onClick={() => handleMarkerClick(index)}
      />
    );
  };

  return (
    <Fragment>
      {markersObjects.map((alarmObject: types.IAlarm, index: number) => {
        if (isEmpty(alarmObject?.location)) return null;

        return (
          <Fragment key={index}>
            {renderAlarm(alarmObject, index)}
            {selectedAlarmId && renderObject(alarmObject, index)}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default AlarmMarkers;
