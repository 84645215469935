import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { connectSignalRThunk } from "./signalREventsActions";

export interface ISignalREvent {
  eventName?: string;
  uniqueId?: string;
  [key: string]: any;
}

export interface ISignalREventsState {
  loading: boolean;
  connected: boolean;
  lastEvent: ISignalREvent | undefined;
}

const initialState: ISignalREventsState = {
  loading: false,
  connected: false,
  lastEvent: undefined,
};

const signalREventsSlice = createSlice({
  name: "signalREvents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(connectSignalRThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(connectSignalRThunk.fulfilled, (state) => {
      state.loading = false;
      state.connected = true;
    });
    builder.addCase(connectSignalRThunk.rejected, (state) => {
      state.loading = false;
      state.connected = false;
    });

    builder.addMatcher(
      (action: AnyAction) =>
        action.payload && typeof action.payload.eventName === "string",
      (state, action) => {
        state.lastEvent = action.payload;
      }
    );
  },
});

export default signalREventsSlice.reducer;
