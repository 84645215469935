import { createAsyncThunk } from "@reduxjs/toolkit";
import { SignalRService } from "app/data/services";

const EVENT_NAME = "EventRaised";

export const connectSignalRThunk = createAsyncThunk(
  "signalREvents/connect",
  async (_, { dispatch }) => {
    try {
      const connection = await SignalRService.createHubConnection();

      connection?.on(EVENT_NAME, (eventData) => {
        if (eventData && eventData?.eventName) {
          const actionName = `signalREvents/${eventData?.eventName}`;
          dispatch({ type: actionName, payload: eventData });
        }
      });

      return connection;
    } catch (error) {
      console.error("Connection failed:", error);
      throw error;
    }
  }
);
